(() => {

//   let sections = Array.from(document.querySelectorAll("body > .content > [class*=component-]")),
//       currentSection = sections[0];
// 
//   const dims = {
//     w:window.innerWidth,
//     h:window.innerHeight
//   }
// 
//   if (window.innerWidth >= 1330 && window.innerHeight >= 768) {
// 
//     const hero = sections[0];
// 
//     tl1 = gsap.timeline({
//       scrollTrigger:{
//         trigger: ".content",
//         start: "top top",
//         end: `+=${2 * dims.h}`,
//         pin: ".component-hero",
//         scrub: true,
//         pinSpacing:true,
//         anticipatePin:1,
//         snap: {
//           snapTo:1,
//           duration:1,
//         },
//         onLeave: function() {
//           setTimeout(() => {
//             hero.style.zIndex = 1;
//             hero.parentElement.style.zIndex = 1;
//           },1000);
//           
//         },
//         onEnterBack: function() {
//           hero.style.zIndex = 2;
//           hero.parentElement.style.zIndex = 2;
//         },
//       }
//     }).to('.component-hero .gallery__item__inner',{
//       transform:"translateY(-100%)",
//       ease: 'power1'
//     },1).to('.component-hero .gallery__highlight',{
//       transform:"translateY(-100%)"
//     },1).to('.component-hero .hero__content',{
//       transform:"translateY(-100%)"
//     },1).to('.component-hero .component__inner',{
//       opacity:0
//     },1).to('.component-hero',{
//       background:'#F7F6F1',
//       opacity:0,
//       pointerEvents:'none'
//     },2);
// 
//     tl2 = gsap.timeline({
//       scrollTrigger:{
//         trigger: ".component-impact-stories",
//         start: "top top",
//         end: `+=${2 * dims.h}`,
//         pin: ".component-impact-stories",
//         scrub: true,
//         pinSpacing:true,
//         anticipatePin:1,
//         // onLeave: function() {
//         //   console.log(hero);
//         //   hero.classList.add("transition-out");
//         // },
//         // onEnterBack: function() {
//         //   hero.classList.remove("transition-out");
//         // }
//       }
//     }).to('.component-impact-stories',{
//       zIndex:3
//     })
// 
//   }
// 
//       function preloadImage(url) {
//         var img=new Image();
//         img.src=url;
//         console.log("Preloading: "+url);
//     }
// 
// ['/wp-content/themes/casestudies-2023-child/build/images/PieChart-Separates-02.png',
//       '/wp-content/themes/casestudies-2023-child/build/images/PieChart-Separates-03.png',
//       '/wp-content/themes/casestudies-2023-child/build/images/PieChart-Separates-04.png',
//       '/wp-content/themes/casestudies-2023-child/build/images/PieChart-Separates-05.png'].forEach(img => preloadImage(img));



// gsap.defaults({overwrite: 'auto', duration: 0.3});
// 
// // stretch out the body height according to however many sections there are. 
// gsap.set(".content", {height: (sections.length * 100) + "%"});
// 
// // create a ScrollTrigger for each section
// sections.forEach((section, i) => {
//   ScrollTrigger.create({
//     // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
//     scroller:".content",
//     start: () => (i - 0.5) * innerHeight,
//     end: () => (i + 0.5) * innerHeight,
//     // when a new section activates (from either direction), set the section accordinglyl.
//     onToggle: self => self.isActive && setSection(section)
//   });
// });
// 
// function setSection(newSection) {
//   if (newSection !== currentSection) {
//     gsap.to(currentSection, {scale: 0.8, autoAlpha: 0})
//     gsap.to(newSection, {scale: 1, autoAlpha: 1});
//     currentSection = newSection;
//   }
// }
// 
// // handles the infinite part, wrapping around at either end....
// ScrollTrigger.create({
//   scroller:".content",
//   start: 1,
//   end: () => ScrollTrigger.maxScroll(window) - 1,
//   onLeaveBack: self => self.scroll(ScrollTrigger.maxScroll(window) - 2),
//   onLeave: self => self.scroll(2)
// }).scroll(2);
//    
})()